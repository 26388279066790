<template>
  <svg
    v-if="icon === 'message'"
    class="w-20 h-auto"
    :fill="fillColor"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 64 64"
    xml:space="preserve"
  >
    <g>
      <path
        d="M57.3,10.7H6.7c-3.7,0-6.7,2.9-6.7,6.7v29.3c0,3.7,2.9,6.7,6.7,6.7h28c1.6,0,2.7-1.1,2.7-2.7c0-1.6-1.1-2.7-2.7-2.7h-28
		c-0.8,0-1.3-0.5-1.3-1.3V17.3v-0.3l22.9,13.9c1.1,0.8,2.4,1.1,3.7,1.1c1.3,0,2.4-0.3,3.7-1.1l22.9-13.9v0.3v12
		c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7v-12C64,13.6,61.1,10.7,57.3,10.7z M32.8,26.1c-0.5,0.3-1.3,0.3-1.9,0L14.1,16h35.7
		L32.8,26.1z"
      />
      <path
        d="M56.5,34.7H48c-1.6,0-2.7,1.1-2.7,2.7c0,1.6,1.1,2.7,2.7,2.7h3.5l-8.8,8.8c-1.1,1.1-1.1,2.7,0,3.7c0.5,0.5,1.3,0.8,1.9,0.8
		c0.8,0,1.3-0.3,1.9-0.8l9.6-9.6V48c0,1.6,1.1,2.7,2.7,2.7s2.7-1.1,2.7-2.7v-8.5C61.3,36.8,59.2,34.7,56.5,34.7z"
      />
    </g>
  </svg>

  <svg
    v-if="icon === 'linkedin'"
    class="w-20 h-auto"
    :fill="fillColor"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 64 64"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M14.5,62.8H5.6c-3.1,0-5.6-2.5-5.6-5.5V27.9c0-3,2.5-5.5,5.6-5.5h8.9c3,0,5.6,2.5,5.6,5.5v29.4
			C20.2,60.3,17.6,62.8,14.5,62.8z M5.6,27.4c-0.3,0-0.6,0.2-0.6,0.5v29.4c0,0.2,0.2,0.5,0.6,0.5h8.9c0.3,0,0.6-0.2,0.6-0.5V27.9
			c0-0.2-0.3-0.5-0.6-0.5H5.6z"
        />
      </g>
      <g>
        <path
          d="M58.4,62.8h-8.9c-3.1,0-5.6-2.5-5.6-5.5V45.2c0-0.6-0.5-1-1.1-1c-0.6,0-1.1,0.5-1.1,1v12c0,3-2.5,5.5-5.6,5.5h-8.9
			c-3.1,0-5.6-2.5-5.6-5.5V27.9c0-3,2.5-5.5,5.6-5.5H36c1.9,0,3.5,0.9,4.5,2.3c0.4-0.2,0.8-0.4,1.1-0.6c3.1-1.4,6.6-1.9,9.8-1.5l0,0
			c7,0.8,12.3,6.7,12.5,13.6l0,21.2C64,60.3,61.5,62.8,58.4,62.8z M42.7,39.2c3.4,0,6.1,2.7,6.1,6v12.1c0,0.2,0.2,0.5,0.6,0.5h8.9
			c0.3,0,0.6-0.2,0.6-0.5V36.2c-0.1-4.3-3.7-8.1-8.1-8.6c-2.3-0.3-4.9,0.1-7.2,1.1c-1.1,0.5-2.2,1.1-3.2,1.8l-3.9,2.8v-5.4
			c0-0.2-0.2-0.5-0.6-0.5h-8.9c-0.3,0-0.6,0.2-0.6,0.5v29.4c0,0.2,0.2,0.5,0.6,0.5H36c0.3,0,0.6-0.2,0.6-0.5v-12
			C36.6,41.9,39.3,39.2,42.7,39.2z"
        />
      </g>
      <g>
        <path
          d="M10.7,21.9c-5.8,0-10.6-4.6-10.6-10.4S4.8,1.2,10.7,1.2s10.6,4.6,10.6,10.4S16.5,21.9,10.7,21.9z M10.7,6.2
			c-3.1,0-5.6,2.4-5.6,5.4s2.5,5.4,5.6,5.4s5.6-2.4,5.6-5.4C16.3,8.5,13.8,6.2,10.7,6.2z"
        />
      </g>
    </g>
  </svg>
  <svg
    v-if="icon === 'github'"
    class="w-20 h-auto"
    :fill="fillColor"
    viewBox="0 0 64 64"
    style="enable-background: new 0 0 64 64"
    xml:space="preserve"
  >
    <path
      d="M64,31C63.3,14.5,49.9,1.1,33.4,0.5C24.6,0.1,16.2,3.3,9.8,9.4C3.5,15.5,0,23.7,0,32.5c0,14.4,9.7,27.1,23.5,30.8
	c0.4,0.1,0.8,0.2,1.2,0.2c1,0,2-0.3,2.8-1c1.1-0.9,1.8-2.2,1.8-3.6v-3.3c0-0.9,0.1-4.9,0.1-5.7c0.1-0.5,0.2-0.9,0.5-1.2
	c0.7-0.9,0.8-2.1,0.3-3.1c-0.5-0.9-1.3-1.5-2.3-1.6c-0.7-0.1-1.4-0.2-1.9-0.4c-0.8-0.2-1.6-0.6-2.4-1c-0.6-0.3-1.2-0.8-1.7-1.4
	c-0.4-0.5-0.8-1.3-1.1-2.3c-0.4-1.2-0.6-2.5-0.6-3.9c0-1.8,0.6-3.3,1.7-4.5l1.1-1.2l-0.6-1.5c-0.2-0.5-0.4-1.2-0.3-2.4
	c1.4,0.5,1.8,0.8,1.8,0.8l0.1,0.1c0.4,0.2,0.7,0.5,1,0.6l1.2,0.7l1-0.3c1.7-0.5,3.4-0.7,5.2-0.7c1.8,0,3.5,0.2,5.2,0.7l1,0.3
	l2.1-1.3c0.7-0.4,1.4-0.8,2.2-1.1c0.1,0.9,0,1.7-0.3,2.5l-0.6,1.5l1.1,1.2c1.1,1.2,1.7,2.7,1.7,4.5c0,1.5-0.2,2.7-0.5,3.8
	c-0.4,1.2-0.8,1.9-1.2,2.4c-0.4,0.5-1,1-1.8,1.5c-0.9,0.5-1.6,0.7-2.4,1c-0.5,0.2-1.3,0.3-2,0.4l-0.1,0c-1,0.2-1.8,0.9-2.2,1.8
	c-0.4,0.9-0.3,1.9,0.3,2.8c0.4,0.5,0.6,1.3,0.6,2.3v7.9c0,1.4,0.7,2.8,1.8,3.6c1.1,0.8,2.6,1.1,4,0.7C55.5,58.9,64.7,45.6,64,31z
	 M40.7,58.2V51c0-0.9-0.1-1.7-0.3-2.4c1.1-0.3,2.2-0.7,3.4-1.4l0.1-0.1c1.3-0.8,2.3-1.6,3.1-2.6c1-1.2,1.6-2.6,2.1-4
	c0.5-1.6,0.8-3.3,0.8-5.4c0-2.6-0.7-4.8-2.1-6.8c0.4-2.1,0.2-4.2-0.7-6.5l-0.4-1.1l-1.1-0.4c-1.1-0.4-2.5-0.3-4.2,0.4
	c-1.1,0.4-2.1,0.9-3.2,1.5L38,22.4c-3.5-0.8-7.3-0.8-10.9,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6-0.4-1.5-0.9-2.9-1.4
	c-1.7-0.8-3.2-0.9-4.4-0.5l-1.1,0.4l-0.4,1.1c-0.9,2.4-1.1,4.5-0.6,6.5c-1.4,1.9-2.1,4.2-2.1,6.8c0,1.9,0.2,3.6,0.8,5.3
	c0.5,1.7,1.2,3.1,2,4.1c1,1.1,2,2,3.2,2.6c1.1,0.6,2.2,1.1,3.4,1.4c0,0,0,0,0,0c-0.1,0.3-0.1,0.6-0.2,1l0,0.3c0,0,0,1.4,0,2.9
	c-2.4-0.8-4.4-1.8-6-3.5c-1.1-1.2-2.3-2.4-2.9-2.8c-1.3-0.6-2.1,0.7-1.8,1.3c0.3,0.9,1.6,1.4,2.3,2.1c0.8,0.8,0.9,1.9,1.6,2.8
	c1,1.4,4.5,3.1,6.9,2.6c0,0.2,0,0.4,0,0.5v2.7C12.9,55.1,5,44.5,5,32.5c0-7.4,2.9-14.3,8.3-19.5C18.4,8.1,25,5.5,32.1,5.5
	c0.4,0,0.7,0,1.1,0C47.1,6,58.4,17.3,59,31.2C59.6,43.3,52.1,54.3,40.7,58.2z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup() {
    const fillColor = '#F6CE7D'

    return { fillColor }
  },
}
</script>

<style></style>
